@import "vars";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");


* {
  font-family: "Inter", sans-serif;
  color: $text;
  box-sizing: border-box;
}

*:focus {
  outline: 3px solid $focus;
}

body {
  background-color: $bg;
  padding: 0.5rem;

  @media screen and (min-width: 900px) {
    padding: 2rem;
  }
}

.visually-hidden {
  font-size: 0;
  opacity: 0;
  position: absolute;
}

h1 {
  @media screen and (min-width: 900px) {
    font-size: 3rem;
  }
}

.intro {
  line-height: 1.4;

  @media screen and (min-width: 900px) {
    max-width: 66%;
    font-size: 1.2rem;
  }
}

table {
  border-collapse: collapse;
  font-size: 1.2rem;
  width: 100%;
}

form {
  margin-bottom: 2rem;
  display: grid;
  gap: 1rem;

  @media screen and (min-width: 900px) {
    margin-top: 3rem;
    gap: 2rem;
    grid-template-columns: 2fr 3fr;
  }
}

td,
th {
  text-align: left;
  padding: 1rem 0;
}

tr {
  border-top: 1px solid $grey3;

  @media screen and (max-width: 900px) {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;

    td,
    th {
      padding: 0;
    }

    td:first-child,
    th:first-child {
      grid-column: 1/5;
      font-size: 1rem;
      color: $grey2;
    }
  }
}

.totals {
  font-weight: 500;
  border-width: 2px;

  &+tr {
    border-width: 2px;
  }
}

input {
  border: 3px solid $text;
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
}

fieldset {
  display: grid;
  gap: 0.5rem;
  padding: 0;
  border: none;

  @media screen and (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 900px) {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}

legend,
label {
  display: block;
  grid-column: 1/3;

  @media screen and (min-width: 900px) {
    font-size: 1.2rem;
  }
}

legend {
  margin-bottom: 0.5rem;
}

label+input {
  margin-top: 0.5rem;
}


.radio {
  position: relative;
  display: block;
  border-radius: 5px;
  text-align: center;

  &:focus-within {
    outline: 3px solid $focus;
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  input:checked+label {
    background: $yellow;
    border: 3px solid darken($yellow, 20);
  }

  label {
    display: block;
    padding: 0.5rem;
    background: white;
    border-radius: 5px;
    cursor: pointer;
    border: 3px solid $grey3;
    height: 100%;

    @media screen and (min-width: 900px) {
      padding: 1rem;
    }
  }
}

small {
  color: $grey2;
}

.no-results {
  color: $grey2;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 5rem;

  @media screen and (min-width: 900px) {
    margin-top: 7rem;
    font-size: 3rem;
  }
}

footer {
  margin-top: 2rem;
  padding: 2rem;
  color: $grey2;
  text-align: center;
}

a {
  color: $grey2;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    background: $focus;
    color: $bg;
    outline: 3px solid $focus;
  }
}

.nil {
  color: $grey2;
}

.record-count {
  display: block;
  margin-bottom: 1rem;
}

.percentage {
  &--positive {
    color: $green;
  }

  &--negative {
    color: $red;
  }
}