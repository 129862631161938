$yellow: #fdf35a;
$text: #333333;
$link: #77755d;
$bg: #f4f4f4;

$focus: #0059ff;

$grey1: $text;
$grey2: #686868;
$grey3: #cccbc3;
$grey4: $bg;

$error: #ff0062;



$green: #217909;
$red: #a20e0e;